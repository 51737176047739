import React, { useEffect, useState } from "react";

import Images from "../../Images";


// const alarms_url = "http://localhost:8083/alarm";
const connectionStatusUrl = process.env.REACT_APP_VIA_OBJECT_API+"/api/object/dtmi%3Acom%3Avia%3AIOService%3AIOService%3B1/ConnectionStatus/propertyValue";
export default function IoService() {
  const [connectionStatus, setConnectionStatus] = useState(null);


  useEffect(() => {
    const checkConnectionStatus = async () => {
      try {
        const response = await fetch(connectionStatusUrl);
        const data = await response.json();
        console.log('io dataset',data)
        setConnectionStatus(data);  // Assuming response JSON contains { value: 0 }
      } catch (error) {
        console.error("Error fetching connection status:", error);
      }
    };

    checkConnectionStatus();
  }, []);

  return (
    connectionStatus === 0 && (
    <div className="fixed top-0 right-0 z-50 bg-white bg-opacity-30 h-screen w-full backdrop-blur-md" style={{'zIndex':'500'}}>
      <div className="absolute w-full top-1/2 font-bold text-3xl text-red-500  ">
          
          <div className="w-80 mx-auto text-center">
              {/* <div className="w-28 mx-auto mb-10"><Images.svg.Logo /></div> */}
              <div className="">I/O SERVICES DOWN</div> 
            </div>
      </div>
    </div>

)
  );
}

