import React, { useState, useEffect, useRef } from "react";

import { useNavigate, useLocation, Link } from "react-router-dom";
import IconPipeValve from "../../../icons/Valve";
import IconViewModule from "../../../icons/Module";
import IconFile from "../../../icons/File";
import IconFolder from "../../../icons/Folder";
import IconDelete from "../../../icons/Delete";
import IconOpenArrow from "../../../icons/OpenArrow";
import IconCloseArrow from "../../../icons/CloseArrow";
import PropertiesEditor from "./propertyEditor";
import RelationshipForm from "./relationshipForm";
import RelationshipsList from "./relationshipsList";
import CommandPanel from "./commandPanel";
import SystemConfigEditor from "./systemConfigEditor";
import ObjectConstants from "./objectConstants";
import EventPanel from "./eventPanel";
import MonitorsList from "./monitorsList";
import RefreshIcon from "./refreshIcon";

const iconMapping = {
  equipment: <IconFolder className="text-blue-300" />,
  valve: <IconPipeValve className="text-blue-300" />,
  subsystem: <IconPipeValve className="text-blue-300" />,
  module: <IconViewModule className="text-blue-300" />,
  category: <IconFolder className="text-blue-300" />,
  default: <IconFile className="text-blue-300" />,
  alart: <IconFolder className="text-blue-300" />
};
export default function ConfigEditor(props) {
  const location = useLocation();
  const [rootItems, setRootItems] = useState([]);
  const [twins, setTwins] = useState([]);
  const [twinRelationships, setTwinRelationships] = useState([]);
  const [openFolders, setOpenFolders] = useState({});
  const [selectedObject, setSelectedItem] = useState(null);
  const [schemas, setSchemas] = useState([]);
  const [interlocks, setInterlocks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const clickedTreeItemId = useRef(null);

  const tabData = [
    { label: "Properties", name: "properties" },
    { label: "Commands", name: "commands" },
    { label: "Events", name: "events" },
    { label: "Configuration", name: "systemConfiguration" },
    { label:"Constants", name:"objectConstants" },
    { label:'Monitors', name:'monitors' }
  ];
  const [activeTab, setActiveTab] = useState(tabData[0]);
  const fetchSchemas = async () => {
    try {
      // Replace 'API_ENDPOINT' with the actual endpoint URL
      const API_URL = `${process.env.REACT_APP_VIA_OBJECT_API}/api/dtdl`;
      const response = await fetch(API_URL);
      if (!response.ok) {
        throw new Error("Failed to fetch");
      }
      const data = await response.json();
      setSchemas(data);
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchData = async () => {
    // Replace 'API_ENDPOINT' with the actual endpoint URL
    const response = await fetch(
      `${process.env.REACT_APP_VIA_OBJECT_API}/api/object/graph`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const jsonData = await response.json();

    setTwins(() => jsonData.digitalTwins);
    setTwinRelationships(() => jsonData.relationships);

    const twinsById = jsonData.digitalTwins.reduce((acc, twin) => {
      acc[twin.$dtId] = { ...twin, children: [] };
      return acc;
    }, {});


    // Link children to their parents according to relationships

    jsonData.relationships.forEach(({ $sourceId, $targetId }, index) => {
      
      if (twinsById[$sourceId] && twinsById[$targetId]) {
        if(twinsById[$targetId].AlarmId) {
          let alarmObject = twinsById[$sourceId].children.find(obj => obj.Name === "Alarms");

          if(!alarmObject) {
            alarmObject = { Name: "Alarms", children: [] };
            alarmObject.children.push(twinsById[$targetId]);
            twinsById[$sourceId].children.unshift(alarmObject)
          } else {
            alarmObject.children.push(twinsById[$targetId]);
          }
          
        } else {
          twinsById[$sourceId].children.push(twinsById[$targetId]);
        }
        
      } else {
        console.log('twinsById[$targetId]', twinsById[$targetId])
      }

    });

    
    // Categorize children by Type under each parent
    const categorizeChildren = (twin) => {
      
      if (twin.children.length > 0) {
        const categorizedChildren = twin.children.reduce((acc, child) => {
          const { Type } = child;
          if (!acc[Type]) acc[Type] = [];
          acc[Type].push(child);
          return acc;
        }, {});

        // twin.children = Object.entries(categorizedChildren).map(
        //   ([Type, items]) => ({
        //     Name: Type,
        //     Type: "Category",
        //     children: items.map(categorizeChildren),
        //   })
        // );
      }
      return twin;
    };

    // Identify root items (those not targeted by any relationship)
    const alarmsArr = {Name : "Alarms", children: []};
    const roots = Object.values(twinsById)
      .filter(
        (twin) => {
          if(twin.AlarmId) {
            alarmsArr.children.push(twin)
            return false
          } else {
            return !jsonData.relationships.some((rel) => rel.$targetId === twin.$dtId)
          }
          
        }
      )
      .map(categorizeChildren);
    roots[0].children.unshift(alarmsArr);
    setRootItems(roots);
  };
  
  useEffect(() => {
    console.log('rootItems', rootItems)
  }, [rootItems])

  const fetchInterlocks = async () => {
    const apiUrl = `${process.env.REACT_APP_VIA_OBJECT_API}/api/Interlock`;
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      setInterlocks(data);

      // Handle successful command execution here (e.g., show a notification)
    } catch (error) {
      console.error("Error :", error);
      // Handle errors here (e.g., show an error message)
    }
  };
  const init = async () => {
    setIsLoading(true);
    try {
      const res = await Promise.all([
        fetchData(),
        fetchSchemas(),
        fetchInterlocks(),
      ]);
      if(rootItems) {
        renderFolders(rootItems)
        setTimeout(() => {
          if(clickedTreeItemId.current) {
            document.getElementById(clickedTreeItemId.current).click()
          }
        }, 100)
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error, "error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const deleteItem = (item) => {
    fetch(
      `${process.env.REACT_APP_VIA_OBJECT_API}/api/object?id=${item["$dtId"]}`,
      {
        method: "DELETE", // Specify the request method
        headers: {
          "Content-Type": "application/json", // Specify the content type in the header
        },
      }
    )
      .then((data) => {
        console.log("Success:", data); // Handle success
      })
      .catch((error) => {
        console.error("Error:", error); // Handle errors
      });

    const updatedTwins = twins.filter((t) => t.$dtId !== item["$dtId"]);

    setTwins(() => updatedTwins);
  };
  const selectItem = (item, e) => {
    if(!item.Type) {
      return
    }
    // Only select the item if it's not a category
    if (item.Type !== "Category") {
      setSelectedItem(item);
      clickedTreeItemId.current = e.currentTarget.id;
    }
  };
  const toggleFolder = (id) => {
    setOpenFolders((prevOpenFolders) => ({
      ...prevOpenFolders,
      [id]: !prevOpenFolders[id],
    }));
  };

  const renderFolders = (items, parentId = "") => (
    <ul style={{ listStyleType: "none", paddingLeft: "8px" }}>
      {items.map((item, index) => {
        const itemId = `${parentId}${index}`;
        const isOpen = openFolders[itemId];
        return (
          <li key={itemId}>
            <div
              className={`flex px-1 py-1 cursor-pointer items-center space-x-2 ${itemId}`}
              id={itemId + "_" +index}
              onClick={(e) => {
                toggleFolder(itemId);
                if (item.Type !== "Category") {
                  // Select the item if it's not a category
                  selectItem(item, e);
                }
              }}
            >
              <div className="w-3 h-4">
                {isOpen && item.children && item.children.length > 0 && (
                  <IconOpenArrow className="text-blue-300" />
                )}
                {!isOpen && item.children && item.children.length > 0 && (
                  <IconCloseArrow className="text-blue-300" />
                )}
              </div>
              <div
                className={`flex px-2 py-1 rounded items-center space-x-2 ${
                  selectedObject?.Name == item?.Name ? "bg-gray-100" : ""
                }`}
              >
                <span>
                  {iconMapping[item?.Type?.toLowerCase()]
                    ? iconMapping[item?.Type?.toLowerCase()]
                    : item.children && item.children.length > 0
                    ? iconMapping.category
                    : iconMapping.default}
                </span>{" "}
                <span>{item.Name}</span>
              </div>
            </div>
            {isOpen &&
              item.children &&
              item.children.length > 0 &&
              renderFolders(item.children, `${itemId}-`)}
          </li>
        );
      })}
    </ul>
  );

  const refreshWindow = () => {
    init()
  }

  return (
    <div className="relative">
      
      <div className="p-2 h-[calc(100vh-186px)] px-20 bg-blue-50 flex gap-4">
        
        <div className="w-full flex flex-1">
          <div className="flex w-full h-full theme-lite-bg p-2 space-x-4">
            <div className="rightPanel shadow-sm" style={{ width: "340px" }}>
              {isLoading && (
                <div className="flex flex-col h-[calc(100vh-254px)] overflow-auto bg-white space-y-1 p-2">
                  <div className="skelton-animate rounded-md bg-gray-100 h-8 w-11/12">
                    {" "}
                  </div>
                  <div className="skelton-animate rounded-md bg-gray-100 h-8  w-11/12">
                    {" "}
                  </div>
                  <div className="skelton-animate rounded-md bg-gray-100 h-8  w-11/12">
                    {" "}
                  </div>
                </div>
              )}
              {!isLoading && (
                <div className="flex h-[calc(100vh-254px)] overflow-auto bg-white">
                  {renderFolders(rootItems)}
                </div>
              )}
            </div>
            {selectedObject ? (
              <>
              <div className="flex flex-col flex-1 gap-2 overflow-auto relative">
              <button className="hidden ctmBtn absolute right-1 top-1 w-6 bg-themecolor-500 text-white px-4 py-2 rounded-md cursor-pointer hover:bg-opacity-80 font-medium text-sm"
        style={{ backgroundColor: '#5656ff' }} onClick={refreshWindow}><span className="gap-3"><RefreshIcon fill="#fff" /></span></button>
                <div className="flex gap-1 mt-2 border-b border-gray-400 overflow-auto ">
                  {tabData.map((tab, index) => (
                    <button
                      key={index}
                      className={`py-1 px-10 rounded-t${
                        activeTab.name === tab.name
                          ? "  bg-red-200"
                          : " bg-gray-300"
                      }`}
                      onClick={() => setActiveTab(tab)}
                    >
                      {tab.label}
                    </button>
                  ))}
                </div>
                <div className="mt-1">
                  {activeTab.name == "properties" && (
                    <PropertiesEditor
                      selectedObject={selectedObject}
                      schemas={schemas}
                      refreshWindow={refreshWindow}
                    />
                  )}
                  {activeTab.name == "commands" && (
                    <div className="flex flex-col space-y-4">
                      <CommandPanel
                        selectedObject={selectedObject}
                        allSchemas={schemas}
                        allInterlocks={interlocks}
                        onUpdate={() => fetchInterlocks()}
                        refreshWindow={refreshWindow}
                      />
                    </div>
                  )}
                  {activeTab.name == "events" && (
                    <div className="flex flex-col space-y-4">
                      <EventPanel
                        selectedObject={selectedObject}
                        allSchemas={schemas}
                        allObject={twins}
                        onUpdate={() => console.log('')}
                        refreshWindow={refreshWindow}
                      />
                    </div>
                  )}
                  {activeTab.name == "systemConfiguration" && (
                    <div className="flex flex-col space-y-4">
                      <SystemConfigEditor  selectedObject={selectedObject} refreshWindow={refreshWindow} />
                    </div>
                  )}
                  {activeTab.name == "objectConstants" && (
                    <div className="flex flex-col space-y-4">
                      <ObjectConstants  selectedObject={selectedObject} refreshWindow={refreshWindow} />
                    </div>
                  )}
                  {activeTab.name == "monitors" && (
                    <div className="flex flex-col space-y-4">
                      <MonitorsList  selectedObject={selectedObject} refreshWindow={refreshWindow} />
                    </div>
                  )}
                </div>
                
              </div>
              </>
            ) : ''}
          </div>
        </div>
      </div>
    </div>
  );
}
