import React from "react";
import Header from "../../Parts/headerComponents/header";
import Footer from "../../Parts/footerComponents/footer";
import TabNav from "../../Parts/tabNav/tabNav";
import { useLocation } from "react-router-dom";

const events_url = process.env.REACT_APP_VIA_EVENTS;
// const events_url = "http://localhost:8083/events";
export default function Events(props) {
  const location = useLocation();
  return (
    <div>
       {location.pathname!="/" &&<Header layoutdesign={props.layoutdesign} />}
      <div className=" px-20 bg-blue-50 flex gap-4">
        <div className="flex-1">
        {location.pathname!="/" &&<TabNav tabs={props.layoutdesign.tabs} type="events"></TabNav>}
          <div className="theme-lite-bg pt-0 overflow-hidden">
            <iframe
              title="Events & Alarms"
              src={events_url}
              className="w-full h-screen"
            ></iframe>
          </div>
        </div>
      </div>
      {location.pathname!="/" &&<Footer layoutdesign={props.layoutdesign} />}
    </div>
  );
}