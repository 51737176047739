// import { useEffect, useRef, useState } from 'react'
// import { ReadAllChannelStates, ReadAllChannels } from "../../api/api";
// import { HubConnection } from '@microsoft/signalr'
// const signalR = require('@microsoft/signalr')

// let connection= HubConnection
// let notification_channel = 'IONotification'
// export default function DevicesAlarm() {
//   const ioChannels = useRef([])
//   const [ioFilteredChannels, setIOFilteredChannels] = useState()

//   const refreshChannels = async () => {
//     const channelStates = await ReadAllChannelStates()
//     channelStates.data.data.forEach((channel) => {
//       const index = ioChannels.current.findIndex((c) => c.id === channel.id)
//       if (index >= 0) {
//         ioChannels.current[index] = channel
//       }
//     })
//     setIOFilteredChannels(() => ioChannels.current)
//   }
//   const fetchIOChannels = async () => {
//     const result = await ReadAllChannels()
//     const channels = result.data.data
//     channels.sort((a, b) => (a.ioObject > b.ioObject ? 1 : -1))
//     ioChannels.current = channels
//     await refreshChannels()
//   }
//   const updateIOChannelState = (channel) => {
//     const channels = [...ioChannels.current]
//     const index = channels.findIndex((c) => c.id === channel.id)
//     channels[index] = channel
//     ioChannels.current = channels
//   }

//   const subscribeToIONotifications = () => {
//     const protocol = new signalR.JsonHubProtocol()

//     const transport = signalR.HttpTransportType.WebSockets

//     const options = {
//       transport,
//       skipNegotiation: true,
//     }
//     connection = new signalR.HubConnectionBuilder()
//       .withUrl(process.env.NX_HW_WS_URL + '/io_notification_hub', options)
//       .withAutomaticReconnect()
//       .withHubProtocol(protocol)
//       .configureLogging(signalR.LogLevel.Information)
//       .build()

//     connection.serverTimeoutInMilliseconds = 120000
//     connection
//       .start()
//       .then(() => console.info('SignalR Connected'))
//       .catch((err) => console.error('SignalR Connection Error: ', err))
//     connection.on('Connected', (data) => {
//       console.log('Connected', data)
//     })
//     connection.on('DisConnected', (data) => {
//       console.log('DisConnected', data)
//     })
//     connection.on(notification_channel, (data) => {
//       console.log('Received Time =====' + new Date())
//       const channel = JSON.parse(data)
//       console.log('Notification Data', channel)
//       updateIOChannelState(channel)
//     })
//   }

//   useEffect(() => {
//     const fetchData = async () => {
//       await fetchIOChannels()
//       subscribeToIONotifications()
//     }
//     fetchData()
//     return () => {
//       if (connection) {
//         setTimeout(() => {
//           connection.stop().then(function () {
//             console.log('IOEditor socket connection closed')
//             connection = null
//           })
//         }, 2000)
//       }
//     }
//   }, [])
//   const classes = [
//     'MAIN.Gate_valve.IN.OP_State',
//     'MAIN.MFC7.In.OP_State',
//     'MAIN.MFC8.In.OP_State',
//     'MAIN.MFC9.In.OP_State',
//     'MAIN.MFC10.In.OP_State',
//     'MAIN.MFC11.In.OP_State',
//     'MAIN.MFC12.In.OP_State',
//     'MAIN.ISOThrottleValve.In.HW_state',
//     'MAIN.DicingChamberThrottleValve.In.HW_state',
//     'MAIN.ISO_RF_Match.In.Device_is_in_Safe_state',
//   ]
//   return (
//     <>
//       {ioFilteredChannels?.map(
//         (channel, i) =>
//           classes.includes(channel?.channelName) && (
//             <>
//               <div className={`border-b border-gray-400 px-2 py-1 justify-between flex text-sm  text-red-500 ${channel?.data?.value == 8 && 'hidden'}`}>
//                   <div className="flex-1"> {channel?.channelName}</div>

//                   <div className="text-xs font-semibold leading-6 -mt-0.5 w-40 text-center">
//                   Alarm
//                   </div>
//                 </div>
//             </>
//           )
//       )}
//     </>
//   )
// }


import React, { useEffect, useRef, useState, useReducer } from 'react';
import { ChannelService, ChannelSubscriptionService } from "@getvia/sdk";
const viaConfig = {
  channelServiceOptions: {
    apiToken: "test",
    baseUrl: process.env.REACT_APP_VIA_CORE_API,
    wsUrl: process.env.REACT_APP_VIA_NOTIFICATION_HUB
  },
};
const defaultE95Configuration = [
  {
    object: "Gate_valve",
    name: "MAIN.Gate_valve.IN.OP_State",
    feedBackChannel: "MAIN.Gate_valve.IN.OP_State",
    currentValue: false,
  },{
    object: "MFC7",
    name: "MAIN.MFC7.In.OP_State",
    feedBackChannel: "MAIN.MFC7.In.OP_State",
    currentValue: false,
  },{
    object: "MFC8",
    name: "MAIN.MFC8.In.OP_State",
    feedBackChannel: "MAIN.MFC8.In.OP_State",
    currentValue: false,
  },{
    object: "MFC9",
    name: "MAIN.MFC9.In.OP_State",
    feedBackChannel: "MAIN.MFC9.In.OP_State",
    currentValue: false,
  },{
    object: "MFC10",
    name: "MAIN.MFC10.In.OP_State",
    feedBackChannel: "MAIN.MFC10.In.OP_State",
    currentValue: false,
  },{
    object: "MFC11",
    name: "MAIN.MFC11.In.OP_State",
    feedBackChannel: "MAIN.MFC11.In.OP_State",
    currentValue: false,
  },{
    object: "MFC12",
    name: "MAIN.MFC12.In.OP_State",
    feedBackChannel: "MAIN.MFC12.In.OP_State",
    currentValue: false,
  },{
    object: "ISOThrottleValve",
    name: "MAIN.ISOThrottleValve.In.HW_state",
    feedBackChannel: "MAIN.ISOThrottleValve.In.HW_state",
    currentValue: false,
  },{
    object: "DicingChamberThrottleValve",
    name: "MAIN.DicingChamberThrottleValve.In.HW_state",
    feedBackChannel: "MAIN.DicingChamberThrottleValve.In.HW_state",
    currentValue: false,
  },{
    object: "ISO_RF_Match",
    name: "MAIN.ISO_RF_Match.In.Device_is_in_Safe_state",
    feedBackChannel: "MAIN.ISO_RF_Match.In.Device_is_in_Safe_state",
    currentValue: false,
  },
  {
    object: "DicingChamberBias_Power",
    name: "MAIN.DicingChamberBias_Power.In.OP_State",
    feedBackChannel: "MAIN.DicingChamberBias_Power.In.OP_State",
    currentValue: null,
  },
  {
    object: "DicingChamberSource_Power",
    name: "MAIN.DicingChamberSource_Power.In.OP_State",
    feedBackChannel: "MAIN.DicingChamberSource_Power.In.OP_State",
    currentValue: null,
  },
  {
    object: "DicingChamberPiraniGuage",
    name: "MAIN.DicingChamberPiraniGuage.In.OP_State",
    feedBackChannel: "MAIN.DicingChamberPiraniGuage.In.OP_State",
    currentValue: null,
  },
  {
    object: "DicingChamberPumpPiraniGuage",
    name: "MAIN.DicingChamberPumpPiraniGuage.In.OP_State",
    feedBackChannel: "MAIN.DicingChamberPumpPiraniGuage.In.OP_State",
    currentValue: null,
  },
  {
    object: "DicingChamberManometer",
    name: "MAIN.DicingChamberManometer.In.OP_State",
    feedBackChannel: "MAIN.DicingChamberManometer.In.OP_State",
    currentValue: null,
  },
  {
    object: "Manifold1",
    name: "MAIN.Manifold1.In.OP_State",
    feedBackChannel: "MAIN.Manifold1.In.OP_State",
    currentValue: null,
  },
  {
    object: "Manifold2",
    name: "MAIN.Manifold2.In.OP_State",
    feedBackChannel: "MAIN.Manifold2.In.OP_State",
    currentValue: null,
  },
  {
    object: "DDI_CUSTOM",
    name: "MAIN.DDI_CUSTOM.In.OP_State",
    feedBackChannel: "MAIN.DDI_CUSTOM.In.OP_State",
    currentValue: null,
  },
  {
    object: "DicingController",
    name: "MAIN.DicingController.In.OP_State",
    feedBackChannel: "MAIN.DicingController.In.OP_State",
    currentValue: null,
  },
  {
    object: "Manifold3",
    name: "MAIN.Manifold3.In.OP_State",
    feedBackChannel: "MAIN.Manifold3.In.OP_State",
    currentValue: null,
  },
 
];
const channelService = new ChannelService(viaConfig.channelServiceOptions);
const channelSubscriptionService = new ChannelSubscriptionService(viaConfig.channelServiceOptions);



export default function E95Screendemo() {
    // const classes = [
    //   'MAIN.DicingController.In.OP_State',
    //   'MAIN.Manifold1.In.OP_State',
    //   'MAIN.Manifold2.In.OP_State',
    //   'MAIN.DDI_CUSTOM.In.OP_State',
    //   'MAIN.DicingChamberManometer.In.OP_State',
    //   'MAIN.DicingChamberPumpPiraniGuage.In.OP_State',
    //   'MAIN.DicingChamberPiraniGuage.In.OP_State',
    //   'MAIN.DicingChamberSource_Power.In.OP_State',
    //   'MAIN.DicingChamberBias_Power.In.OP_State',
    //   'MAIN.Gate_valve.IN.OP_State',
    //   'MAIN.MFC7.In.OP_State',
    //   'MAIN.MFC8.In.OP_State',
    //   'MAIN.MFC9.In.OP_State',
    //   'MAIN.MFC10.In.OP_State',
    //   'MAIN.MFC11.In.OP_State',
    //   'MAIN.MFC12.In.OP_State',
    //   'MAIN.ISOThrottleValve.In.HW_state',
    //   'MAIN.DicingChamberThrottleValve.In.HW_state',
    //   'MAIN.ISO_RF_Match.In.Device_is_in_Safe_state',
    //   'MAIN.Manifold3.In.OP_State'
    //   ]
  const e95Configuration = useRef(defaultE95Configuration);
  const channels = useRef();
  const subscriptionRef = useRef();
  
  const [alarm,setalarm] = useState([{ "feedBackChannel": "", "currentValue":""}]);
  const [e95State, sete95State] = useState();
  const fetchChannelsStates = async () => {
    const response = await channelService.ChannelStates();
    channels.current = response;
    updateState(channels.current);
  };
  const updateState = (updatedChannels) => {

    let index = -1
    let e95ConfigurationState = e95Configuration.current;
    updatedChannels.forEach((item) => {
      const config = e95ConfigurationState.find((obj, objIndex) => {
        index = objIndex;
        return obj.feedBackChannel === item.channelName
      })
      if (config) {
        config.currentValue = item.data.value;
        e95ConfigurationState[index] = config;

      }
    })
    console.log('e95ConfigurationState alarm',e95ConfigurationState)
    sete95State(() => e95ConfigurationState);
    e95ConfigurationState?.map((channel, i) =>(
      channel.name != "Sequence" &&
        channel?.currentValue!=8 ? 
        setalarm([{ "feedBackChannel": channel.feedBackChannel, "currentValue":channel.currentValue}])
        :''
    ))
    
  }
 
  const subscribeToNotification = () => {
    const onNewMessage = (message) => {
      const channel = JSON.parse(message);
      updateState([channel])

    };

    let channels = [];

    e95Configuration.current.forEach(config => {
      channels?.push(config.feedBackChannel);
    })
    const subscription = {
      channels: channels,
      onNewMessage: onNewMessage,
    };

    subscriptionRef.current = subscription;
    channelSubscriptionService.subscribe(subscription);
  };
  const unSubscribe = () => {
    if (subscriptionRef.current && subscriptionRef.current.channels) {
      channelSubscriptionService.unSubscribe(subscriptionRef.current);
    }


  };




  useEffect(() => {
    const fetChannelData = async () => {
      await fetchChannelsStates();
      subscribeToNotification();
    };
    fetChannelData();
    return () => {
      unSubscribe();
    };
  }, []);


  return (
<>
      {alarm?.sort((a, b) => (a?.currentValue === 8 ? 1 : -1)).map(
        (channel, i) =>
          // i<1&&
          // classes.includes(channel?.feedBackChannel) && 
            <>
               
                <div 
                className=" overflow-hidden flex-1 rounded-md no-scrollbar  transition-all  inset-0  blink-button ">
                  <div class=" px-2  pb-2 justify-between flex text-md   text-red-500 ">
                    <div class="flex-1 font-bold"> {channel?.feedBackChannel}</div>
                    <div class="text-md font-bold leading-6  w-40 text-center">{channel?.currentValue == 8 ? 'Operational': 'Not Operational'}</div>
                  </div>
                </div>
              {/* <div className={`border-b border-gray-400 px-2 py-1 justify-between flex text-md   ${channel?.currentValue == 8 ? '': 'text-red-500 '}`}>
                  <div className="flex-1"> {channel?.feedBackChannel}</div>

                  <div className="text-md font-semibold leading-6 -mt-0.5 w-40 text-center">
                  {channel?.currentValue == 8 ? 'Operational': 'Not Operational'}
                  </div>
                </div>
                <div 
                className=" overflow-hidden flex-1 rounded-md no-scrollbar  transition-all  inset-0  blink-button ">
                  <div class=" px-2  pb-2 justify-between flex text-md   text-red-500 ">
                    <div class="flex-1 font-bold"> MAIN.DicingChamberThrottleValve.In.HW_state</div>
                    <div class="text-md font-bold leading-6  w-40 text-center">Not Operational</div>
                  </div>
                </div> */}
            </>
      )}
    </>
  )
}