import React from 'react';
import Images from '../../../Images';
import Header from '../../Parts/headerComponents/header';
import Footer from '../../Parts/footerComponents/footer';
import TabNav from '../../Parts/tabNav/tabNav';
import E95sidemenu from './e95sidemenu';
import E95Screendemo from './e95Screendemo';
import { useLocation } from 'react-router-dom';
let content = [
  { 
    name:'E95Screendemo',
    module:<E95Screendemo/>,
    type:'image',
    data:{className:'mx-auto p-1',
    e95options:[
      {
        name:'ATM1',channelName:'ATM1.WfrPresence',
        options:{
          placementoptions:[
            {name:'Remove Wafer from ATM',channelName:'ATM1.WfrPresence',value:false},
            {name:'Add Wafer to ATM',channelName:'ATM1.WfrPresence',value:true},
          ],
          waferoptions:[
            {name:'Move Wafer to LLA',channelName:'LLA.WfrPresence',value:true,waferfrom:'ATM1.WfrPresence'},
            {name:'Move Wafer to LLB',channelName:'LLB.WfrPresence',value:true,waferfrom:'ATM1.WfrPresence'},
            {name:'Move Wafer to LP1',channelName:'LP1.WfrPresence',value:true,waferfrom:'ATM1.WfrPresence'},
            {name:'Move Wafer to LP2',channelName:'LP2.WfrPresence',value:true,waferfrom:'ATM1.WfrPresence'},
          ],
          atmoptions:[
            {name:'Move ATM to LLA',channelName:'ATM1.Station',value:'LLA'},
            {name:'Move ATM to LLB',channelName:'ATM1.Station',value:'LLB'},
            {name:'Move ATM to LP1',channelName:'ATM1.Station',value:'LP1'},
            {name:'Move ATM to LP2',channelName:'ATM1.Station',value:'LP2'},
          ],type:'ATM'
        }
      },
      {
        name:'ATM2',channelName:'ATM2.WfrPresence',
        options:{
          placementoptions:[
            {name:'Remove Wafer from VTM',channelName:'ATM2.WfrPresence',value:false},
            {name:'Add Wafer to VTM',channelName:'ATM2.WfrPresence',value:true},
          ],
          waferoptions:[
            {name:'Move Wafer to LLA',channelName:'LLA.WfrPresence',value:true,waferfrom:'ATM2.WfrPresence'},
            {name:'Move Wafer to LLB',channelName:'LLB.WfrPresence',value:true,waferfrom:'ATM2.WfrPresence'},
            {name:'Move Wafer to Isotropic',channelName:'PM1.WfrPresence',value:true,waferfrom:'ATM2.WfrPresence'},
            {name:'Move Wafer to Dicing',channelName:'PM2.WfrPresence',value:true,waferfrom:'ATM2.WfrPresence'},
          ],
          atmoptions:[
            {name:'Move VTM to LLA',channelName:'ATM2.Station',value:'LLA'},
            {name:'Move VTM to LLB',channelName:'ATM2.Station',value:'LLB'},
            {name:'Move VTM to Isotropic',channelName:'ATM2.Station',value:'PM1'},
            {name:'Move VTM to Dicing',channelName:'ATM2.Station',value:'PM2'},
          ],type:'VTM'
        }
      },
      {
        name:'LLA',channelName:'LLA.WfrPresence',
        options:{
          placementoptions:[
            {name:'Remove Wafer from LLA',channelName:'LLA.WfrPresence',value:false},
            {name:'Add Wafer to LLA',channelName:'LLA.WfrPresence',value:true},
          ],
          waferoptions:[
            {name:'Move Wafer to ATM',channelName:'ATM1.WfrPresence',value:true,waferfrom:'LLA.WfrPresence'},
            {name:'Move Wafer to VTM',channelName:'ATM2.WfrPresence',value:true,waferfrom:'LLA.WfrPresence'},
          ]
        }
      },
      {
        name:'LLB',channelName:'LLB.WfrPresence',
        options:{
          placementoptions:[
            {name:'Remove Wafer from LLB',channelName:'LLB.WfrPresence',value:false},
            {name:'Add Wafer to LLB',channelName:'LLB.WfrPresence',value:true},
          ],
          waferoptions:[
            {name:'Move Wafer to ATM',channelName:'ATM1.WfrPresence',value:true,waferfrom:'LLB.WfrPresence'},
            {name:'Move Wafer to VTM',channelName:'ATM2.WfrPresence',value:true,waferfrom:'LLB.WfrPresence'},
          ]
        }
      },
      {
        name:'LP1',channelName:'LP1.WfrPresence',
        options:{
          placementoptions:[
            {name:'Remove Wafer from LP1',channelName:'LP1.WfrPresence',value:false},
            {name:'Load Wafer to LP1',channelName:'LP1.WfrPresence',value:true},
          ],
          waferoptions:[
            {name:'Move Wafer to ATM1',channelName:'ATM1.WfrPresence',value:true,waferfrom:'LP1.WfrPresence'}
          ]
        }
      },
      {
        name:'LP2',channelName:'LP2.WfrPresence',
        options:{
          placementoptions:[
            {name:'Remove Wafer from LP2',channelName:'LP2.WfrPresence',value:false},
            {name:'Load Wafer to LP2',channelName:'LP2.WfrPresence',value:true},
          ],
          waferoptions:[
            {name:'Move Wafer to ATM1',channelName:'ATM1.WfrPresence',value:true,waferfrom:'LP2.WfrPresence'}
          ]
        }
      },
      {
        name:'LLA',channelName:'LLA.DoorSts',
        options:{
          placementoptions:[
            {name:'Close Door of LLA',channelName:'LLA.DoorSts',value:false},
            {name:'Open Door of LLA',channelName:'LLA.DoorSts',value:true},
          ]
        }
      },
      {
        name:'LLB',channelName:'LLB.DoorSts',
        options:{
          placementoptions:[
            {name:'Close Door of LLB',channelName:'LLB.DoorSts',value:false},
            {name:'Open Door of LLB',channelName:'LLB.DoorSts',value:true},
          ]
        }
      },
    ]
  }
  }
]

  export default function E95(props) { 
    const location = useLocation();  
    return (
    <div className="">
      {location.pathname!="/" &&<Header layoutdesign={props.layoutdesign}/>}
      <div className=' px-20 bg-blue-50 '>
       {location.pathname!="/" &&<TabNav tabs={props.layoutdesign.tabs} type='home'></TabNav>}
      </div>
      <div className='px-20 bg-blue-50 flex gap-4'>
        
        <div className='flex-1'>
          
         
          <div className='theme-lite-bg  px-3 rounded-r-lg rounded-b-lg'>
            {content.map(({ module, data}, index) => (
              <div key={index}>
                  {React.cloneElement(module,data)}
                </div>
            ))}
          </div>
        </div>
        <div className=' rounded-sm w-24'>
          <E95sidemenu />

        </div>

      </div>
     {location.pathname!="/" && <Footer layoutdesign={props.layoutdesign}/>}
    </div>
    )
  }